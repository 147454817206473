<template>
  <a-card>
    <div class="spin-load" v-if="!showData">
      <a-spin :spinning="!showData">
      </a-spin>
    </div>
    <div class="app-container" v-if="showData">
      <a-form ref="form" :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="佣金发放比例" class="margin-bottom-10">
          <a-input v-model="form.platformGiveRatio" placeholder="" allow-clear style="width: 300px">
            <template #prefix>
              %
            </template>
          </a-input>
          <span class="tip">(佣金发放金额所占利润百分比，不能大于100%小于0%)</span>
        </a-form-item>
        <a-form-item label="佣金发放模式" class="margin-bottom-10">
          <a-radio-group v-model="form.upperLevelGiveType" button-style="solid">
            <a-radio-button v-for="(d, index) in option" :key="index" :value="parseInt(d.value)">{{ d.label
            }}</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="佣金返利" class="margin-bottom-10">
          <div class="borkerage-class">
            <div v-for=" item in brokerageList" class="borkerage-item-class">
              <div class="borkerage-item-title-class">{{ item.title }}</div>
              <div class="borkerage-item-level-class">
                <div v-for="config in item.levelConfig" style="margin-bottom: 10px">
                  <a-input addon-before="一级" :addon-after="form.upperLevelGiveType == 1 ? '%' : '元'"
                    v-model:value="config.data" v-if="config.configLevel == 1" />
                  <a-input addon-before="二级" :addon-after="form.upperLevelGiveType == 1 ? '%' : '元'"
                    v-model:value="config.data" v-if="config.configLevel == 2" />
                  <a-input addon-before="三级" :addon-after="form.upperLevelGiveType == 1 ? '%' : '元'"
                    v-model:value="config.data" v-if="config.configLevel == 3" />
                </div>
              </div>

            </div>
          </div>

        </a-form-item>
        <a-row class="margin-top-xl">
          <a-col :span="4"></a-col>
          <a-col :span="8">
            <a-button size="large" type="primary" :loading="loading" :disabled="loading" @click="onsubmit('ruleForm')"
              style="width:200px">
              确定
            </a-button>
          </a-col>
        </a-row>
        <!-- <a-form-item :wrapper-col="{ span: 12, offset: 12 }">
          <a-button type="primary" :loading="loading" @click="onsubmit('ruleForm')">确 定
          </a-button>
        </a-form-item> -->
      </a-form>
    </div>
  </a-card>
</template>
<script>
import { getBrokerageConfig, saveOrEditBrokerageConfig, getGoodsBrokerageConfig, saveOrEditGoodsBrokerageConfig } from "@/api/distribution.js"
export default {
  name: "CommissionSettings",
  props: {
    goodsId: {
      type: Number
    }
  },
  data() {
    return {
      showData: false,
      option: [{
        label: '按百分比发放',
        value: 1
      }, {
        label: '按固定金额发放',
        value: 2
      }],
      loading: false,
      queryParams: {
        name: ''
      },
      form: {
        platformGiveRatio: 0,
        brokerageRatio: 100,
        upperLevelGiveType: 1
      },
      brokerageList: []
    };
  },
  created() {
    //获取默认分销佣金基础配置信息
    console.log(!this.goodsId)
    if (!this.goodsId) {
      this.getgetBrokerageConfig();
    } else {
      //获取商品佣金分配
      this.getGoodsBrokerageConfig()

    }
  },
  methods: {
    //保存数据
    onsubmit() {
      let platformGiveRatio = this.form.platformGiveRatio
      if (platformGiveRatio > 100 || platformGiveRatio < 0) {
        this.$message.error("佣金发放金额所占利润的百分比不能大于100%小于0%")
        return
      }
      let brokerageJson = Object.fromEntries(
        this.brokerageList.map(item => {
          let levelConfig = Object.fromEntries(item.levelConfig.map(item => [item.configLevel, item.data]))
          let brokerage = {
            title: item.title,
            levelConfig: levelConfig
          }
          return [item.levelId, brokerage]
        })
      )

      for (let levelId in brokerageJson) {
        //身份等级配置
        let levelConfig = brokerageJson[levelId].levelConfig
        const level = [1, 2, 3]
        for (let index in level) {
          let level1 = level[index]
          let level2 = level1
          let level3 = level1
          if (level1 == 1) {
            level2 = level2 + 1
            level3 = level3 + 2
          } else if (level1 == 2) {
            level2 = level2 - 1
            level3 = level3 + 1
          } else if (level1 == 3) {
            level2 = level2 - 2
            level3 = level3 - 1
          }
          //处理一层
          let oneContes = (levelConfig[level1] ? levelConfig[level1] : 0) * 1
          if (oneContes > 100) {
            // console.log("等级之和："+oneContes)
            this.$message.error("佣金等级分配等级之和大于100")
            return;
          }
          //处理二层
          for (let levelId in brokerageJson) {
            let levelConfig = brokerageJson[levelId].levelConfig
            let con2 = (levelConfig[level2] ? levelConfig[level2] : 0) * 1
            oneContes = oneContes + con2
            if (oneContes > 100) {
              //console.log("等级之和："+oneContes)
              this.$message.error("佣金等级分配等级之和大于100")
              return;
            }
            //处理三层
            for (let levelId in brokerageJson) {
              let levelConfig = brokerageJson[levelId].levelConfig
              let con3 = (levelConfig[level3] ? levelConfig[level3] : 0) * 1
              oneContes = oneContes + con3
              if (oneContes > 100) {
                //console.log("等级之和："+oneContes)
                this.$message.error("佣金等级分配等级之和大于100")
                return;
              }
              oneContes = oneContes - con3
            }
            oneContes = oneContes - con2
          }
        }
      }
      //保存
      this.form.brokerageJson = JSON.stringify(brokerageJson)
      this.form.brokerageRatio = 100
      this.loading = true
      if (this.goodsId) {
        //保存商品
        this.form.goodsId = this.goodsId
        saveOrEditGoodsBrokerageConfig(this.form).then(res => {
          if (res.success) {
            this.$message.success("设置成功");
          }
          this.loading = false
        })
      } else {
        saveOrEditBrokerageConfig(this.form).then(res => {
          if (res.success) {
            this.$message.success("设置成功");
          }
          this.loading = false
        })
      }
    },
    //获取分销佣金基础配置信息
    getgetBrokerageConfig() {
      this.showData = false;
      getBrokerageConfig().then(res => {
        if (res.success) {
          this.form = res.data
          let brokerageJson = JSON.parse(this.form.brokerageJson)
          for (let levelId in brokerageJson) {
            let leaveData = brokerageJson[levelId]
            let levelConfig = []
            for (let configLevel in leaveData.levelConfig) {
              levelConfig.push({ configLevel: configLevel, data: leaveData.levelConfig[configLevel] })
            }
            this.brokerageList.push({
              levelId: levelId,
              title: leaveData.title,
              levelConfig: levelConfig
            })
          }
        }
        this.showData = true
      })
    },
    getGoodsBrokerageConfig() {
      this.showData = false;
      getGoodsBrokerageConfig(this.goodsId).then(res => {
        if (res.success) {
          this.form = res.data
          let brokerageJson = JSON.parse(this.form.brokerageJson)
          for (let levelId in brokerageJson) {
            let leaveData = brokerageJson[levelId]
            let levelConfig = []
            for (let configLevel in leaveData.levelConfig) {
              levelConfig.push({ configLevel: configLevel, data: leaveData.levelConfig[configLevel] })
            }
            this.brokerageList.push({
              levelId: levelId,
              title: leaveData.title,
              levelConfig: levelConfig
            })
          }
        }
        this.showData = true
      })
    }
  }
};
</script>
<style scoped lang="less">
.tip {
  line-height: 20px !important;
  font-size: 12px;
  margin-left: 10px;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.spin-load {
  text-align: center;
}

.borkerage-class {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  .borkerage-item-class {
    border: 1px solid #e6eaf1;
    border-radius: 4px;
    margin: 8px;
    -webkit-transition: all .2s cubic-bezier(.55, .055, .675, .19);
    transition: all .2s cubic-bezier(.55, .055, .675, .19);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    width: 268px;
    text-align: center;

    .borkerage-item-title-class {
      border-bottom: 1px solid #e6eaf1;
      font-weight: 700;
      background: #fafafa;
    }

    .borkerage-item-level-class {
      padding: 12px 24px;
    }
  }
}</style>