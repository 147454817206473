<template>
  <page-header-wrapper>
  <!--默认佣金分销-->
    <commission-settings></commission-settings>
    <!--商品佣金分销-->
    <!--<commission-settings :goods-id="1000001"></commission-settings>-->
  </page-header-wrapper>
</template>
<script>
import CommissionSettings from "@/views/agent/components/commissionSetting";
export default {
  name: "Agent",
  components: {CommissionSettings},
  data() {
    return {

    };
  },
  created() {
  },
  methods: {
  }
}
</script>
<style scoped lang="less">

</style>